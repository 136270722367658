import * as React from 'react';
import PageSeo from '../../components/Seo/PageSeo';
import PageTitle from '../../components/PageTitle';
import styled from 'styled-components';
import PageSection from '../../components/PageSection';
import { ThreeColumnLayout, FourColumnLayout, GradientCard, CalloutBanner, ImageWithScroll, Image, PageSeparator, NotationBlock } from '../../components';
import PillsIcon from '../../assets/images/icon-pills.svg';
import CalendarIcon from '../../assets/images/icon-calendar.svg';
import VomitingIcon from '../../assets/images/icon-vomiting.svg';
import CircleArrowIcon from '../../assets/images/icon-circle-arrow.svg';
import CircleArrowRotated from '../../assets/images/circle-arrow-rotated.svg';
import DosingScheduleCard1 from '../../assets/images/dose-schedule-card1.svg';
import DosingScheduleCard2 from '../../assets/images/dose-schedule-card2.svg';
import DosingScheduleCard3 from '../../assets/images/dose-schedule-card3.svg';
import DosingScheduleCard4 from '../../assets/images/dose-schedule-card4.svg';
import { respondTo } from '../../styles/shared/breakpoints';
import DosingPills from '../../assets/images/dosing-pills.png';
import ArTable from '../../assets/images/AR-Table.svg';
import { brandBlueDark, brandBlueLight, grayImageAnno, charcoal, charcoal2 } from '../../styles/shared/colors';

// SEO INFORMATION
const pageMeta = {
  title: 'Dosing for WELIREG™ (belzutifan)',
  description: 'Health care professionals may find information about dosing and dose modifications for WELIREG™ (belzutifan).',
  keywords:  'welireg dosing in advanced rcc patients, belzutifan dosing in advanced rcc patients',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/dosing/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/advanced-renal-cell-carcinoma/dosing/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","dosageForm":"Convenient, once-daily, oral dosing","administrationRoute":"The recommended dose of WELIREG is 120 mg (three 40-mg tablets) once daily until disease progression or unacceptable toxicity","prescribingInfo":"https://www.merck.com/product/usa/pi_circulars/w/welireg/welireg_pi.pdf"}`
  ]
}

const StandardSection = styled(PageSection)`
  display: flex;
  justify-content: center;
  padding: 0;
`;

const CenteredBoldText = styled.h5`
  color: ${brandBlueDark};
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;

  max-width: 482px;
  margin: 0px auto;
`;

const ImageAnno = styled.div`
  color: ${grayImageAnno};

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  text-align: center;
  margin-bottom: 5px;
`;

const ManageAdverseReactions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  h3 {
    color: ${brandBlueDark};
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
  }

  h4 {
    color: ${brandBlueLight};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
  }

  h5 {
    color: ${charcoal2};
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  ul {
    list-style: none;

    li {
      color: ${charcoal};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;    
      margin-bottom: 10px;

      text-indent: -10px;
      margin-left: 20px;
      
      &::before {
        content: "•";
        color: rgb(214, 50, 39);
        font-weight: bold;
        font-size: 20px;
        display: inline-block;
        width: 10px;
        margin-left: 0px;
      }

    }

    &.sub-list li {
      margin-left: 40px;
      
      &::before {
        content: "–";
        margin-left: 00px;
      }
    }
  }
`;

const Desktop4Col = styled(FourColumnLayout)`
  display: none;
  ${respondTo.sm`
    display: block;
  `}
`;

const Mobile4Col = styled(FourColumnLayout)`
  display: block;
  ${respondTo.sm`
    display: none;
  `}
`;

const anchorLinkItems = ['Dosing', 'Dose Modification'];

const cardsData = [
  {
    icon: PillsIcon,
    title: 'Advise patients to swallow tablets whole.',
    description: 'Do not chew, crush, or split WELIREG prior to swallowing.',
  },
  {
    icon: CalendarIcon,
    title: 'If a dose of WELIREG is missed, it can be taken as soon as possible on the same day.',
    description: 'Resume the regular daily dose schedule for WELIREG the next day. Do not take extra tablets to make up for the missed dose.',
  },
  {
    icon: VomitingIcon,
    title: 'If vomiting occurs any time after taking WELIREG, do not retake the dose.',
    description: 'Take the next dose on the next day.',
  },
];

const calloutBanner = {
  title: 'Explore helpful links and resources',
  bcbContinual: true,
  links: [
    {
      label: 'Learn More',
      url: '/advanced-renal-cell-carcinoma/resources-and-access/',
    },
  ],
};

const doseModScheduleFourColumn = {
  gapIcon: CircleArrowIcon,
  iconWidth: '27px',
  gapSize: '10px',
  title: 'Dose modification (if appropriate)',
  justifyContent: 'left',
};

const doseModScheduleFourColumnMobile = {
  gapIcon: CircleArrowRotated,
  iconWidth: '27px',
  gapSize: '10px',
  title: 'Dose modification (if appropriate)',
  justifyContent: 'left',
};

const dosingImageData= {
  footnotes: [
  ],
  definitions: '',
};

const IndexPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/advanced-renal-cell-carcinoma/dosing/" />
      </PageSeo>

      <PageTitle anchorLinksData={anchorLinkItems}>
        Dosing for WELIREG™ (belzutifan)
      </PageTitle>

      <StandardSection>
        <PageSeparator topPadding='24px' bottomPadding='0px' />
      </StandardSection>

      <div id='Dosing'>
        <StandardSection>
          <Image
            title="Convenient, once-daily, oral dosing"
            subtext="The recommended dose of WELIREG is 120 mg (three 40-mg tablets) once daily until disease progression or unacceptable toxicity "
            graphTitle=""
            imgPath={DosingPills}
            mobImgPath={DosingPills}
            imgAlt="WELIREGTM (belzutifan) 40-mg Tablets"
            maxWidth="280px"
            topPadding='2px'
            {...dosingImageData}
          />
        </StandardSection>
      </div>

      <StandardSection>
        <ImageAnno>Not actual size.</ImageAnno>
      </StandardSection>

      <StandardSection >
        <CenteredBoldText>WELIREG should be taken at the same time each day and may be taken with or without food</CenteredBoldText>
      </StandardSection>

      <StandardSection>
        <PageSeparator topPadding='40px' bottomPadding='0px' />
      </StandardSection>

      <ThreeColumnLayout>
        {
          cardsData.map((card, index) => (
            <GradientCard key={index} icon={card.icon} title={card.title} description={card.description} linkText={card.linkText} linkUrl={card.linkUrl} />
          ))
        }
      </ThreeColumnLayout>

      <StandardSection>
        <PageSeparator topPadding='40px' bottomBorder={true} />
        <PageSeparator topPadding='40px' />
      </StandardSection>

      <div id='Dose-Modification'>
      <StandardSection>
        <ManageAdverseReactions>
          <h3>Manage adverse reactions with recommended dose modifications</h3>
          <h4>Monitor for anemia and oxygen saturation before initiation of, and periodically throughout, treatment with WELIREG.</h4>
          <h5>In the LITESPARK-005 clinical trial:</h5>
          <ul>
            <li>Decreased hemoglobin occurred in 88% of patients and 29% had Grade 3 events</li>
            <ul className='sub-list'>
              <li>Median time to onset of anemia was 29 days (range: 1 day to 16.6 months)</li>
            </ul>
            <li>Hypoxia occurred in 15% of patients and 10% had Grade 3 events</li>
            <ul className='sub-list'>
              <li>Median time to onset of hypoxia was 30.5 days (range: 1 day to 21.1 months)</li>
            </ul>

          </ul>
        </ManageAdverseReactions>
      </StandardSection>
      </div>

      <StandardSection>
        <PageSeparator topPadding='30px' bottomPadding='0px' />
      </StandardSection>

        <StandardSection>
          <ImageWithScroll url={ArTable} alt={'Adverse Reactions With Recommended Dose Modifications for WELIREGTM (belzutifan) Based on Data From the LITESPARK-005 Clinical Trial'} />
        </StandardSection>


      <StandardSection>
        <NotationBlock supressGap definitions={[<><p><sup className="footnote-super">a</sup>Graded per NCI CTCAE v5.0.</p><br/><p>NCI CTCAE = National Cancer Institute Common Terminology Criteria for Adverse Events.</p></>]} />
      </StandardSection>

      <StandardSection>
        <PageSeparator topPadding='40px' bottomPadding='0px' />
      </StandardSection>

      <Desktop4Col {...doseModScheduleFourColumn}>
        <img src={DosingScheduleCard1} alt="Starting Dose for WELIREGTM (belzutifan) Is 120 mg Orally Once Daily" />
        <img src={DosingScheduleCard2} alt="If a Dose Reduction for WELIREGTM (belzutifan) Is Appropriate to Manage Adverse Reactions, Reduce to 80 mg Orally Once Daily"/>
        <img src={DosingScheduleCard3} alt="If a Second Dose Reduction for WELIREGTM (belzutifan) Is Appropriate to Manage Adverse Reactions, Reduce to 40 mg Orally Once Daily"/>
        <img src={DosingScheduleCard4} alt="If a Third Dose Reduction for WELIREGTM (belzutifan) Is Appropriate to Manage Adverse Reactions Permanently Discontinue"/>
      </Desktop4Col>
      <Mobile4Col {...doseModScheduleFourColumnMobile}>
        <img src={DosingScheduleCard1} alt="Starting Dose for WELIREGTM (belzutifan) Is 120 mg Orally Once Daily"/>
        <img src={DosingScheduleCard2} alt="If a Dose Reduction for WELIREGTM (belzutifan) Is Appropriate to Manage Adverse Reactions, Reduce to 80 mg Orally Once Daily"/>
        <img src={DosingScheduleCard3} alt="If a Second Dose Reduction for WELIREGTM (belzutifan) Is Appropriate to Manage Adverse Reactions, Reduce to 40 mg Orally Once Daily"/>
        <img src={DosingScheduleCard4} alt="If a Third Dose Reduction for WELIREGTM (belzutifan) Is Appropriate to Manage Adverse Reactions Permanently Discontinue"/>
      </Mobile4Col>

      <StandardSection>
        <PageSeparator topPadding='40px' bottomPadding='0px' />
      </StandardSection>

      <CalloutBanner {...calloutBanner} />

    </>
  );
};

export default IndexPage;
